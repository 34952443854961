.software{
    min-height: 100vh;
    background-image: url('starspic.png');
    background-repeat: repeat;
    background-size: cover;
}

.project-padding{
    padding-top: 0.8em;
}




* { margin: 0; }