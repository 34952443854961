.about{
    min-height: 100vh;
    background-image: url('starspic.png');
    background-repeat: repeat;
    background-size: cover;
}

.top-space{
    padding-top: 10vh;
}

.about-text-background{
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background-image: linear-gradient(to bottom right, rgba(96, 96, 96, 0.975), rgba(96, 96, 96, 0.525));
    border-radius: 25px;
    min-height: 40%;
    width: 50%
}

.about-text-background-mobile{
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background-image: linear-gradient(to bottom right, rgba(96, 96, 96, 0.975), rgba(96, 96, 96, 0.525));
    border-radius: 25px;
    min-height: 40%;
    min-width: 50%
}

.about-heading{
    display: flex;
    text-decoration: none;
    color: gold;
    font-family: Arial, Helvetica, sans-serif;
    align-items: flex-start;
    /*padding-top: 1.5%;
    padding-left: 2.5%;
    padding-right: 2.5%;*/
    padding-top: 2%;
    padding-left: 3%;
    padding-right: 3%;
}

.about-subheading{
    display: flex;
    text-decoration: none;
    color: rgb(253, 235, 136);
    font-family: Arial, Helvetica, sans-serif;
    align-items: flex-start;
    /*
    padding-top: 1.2%;
    padding-left: 2.5%;
    padding-right: 2.5%;*/
    padding-left: 1%;
    padding-right: 3%;
    font-size: large;
    font-weight: bold;

}

.about-subheading-for-non-software{
        display: flex;
        text-decoration: none;
        color: rgb(253, 235, 136);
        font-family: Arial, Helvetica, sans-serif;
        align-items: flex-start;
        /*
        padding-top: 1.2%;
        padding-left: 2.5%;
        padding-right: 2.5%;*/
        padding-left: 3%;
        padding-right: 3%;
        padding-top: 1.2%;
        font-size: large;
        font-weight: bold;

}

.about-subheading:hover{
    text-decoration: underline;
}


.privacy-link{
    display: flex;
    text-decoration: none;
    color: rgb(253, 235, 136);
    font-family: Arial, Helvetica, sans-serif;
    align-items: flex-start;
    font-size: large;
    font-weight: bold;

}

.privacy-links{
    min-height: 100%;
    width: 50%;
    display: flex;
    justify-content: flex-start;
    padding-left: 2.5%;
    padding-top: 1.2%;
    align-items: center;
    list-style: none;
}

.about-text{
    display: flex;
    text-decoration: none;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    /*
    padding-top: 1.4%;
    padding-left: 2.5%;
    padding-right: 2.5%;
    padding-bottom: 2.5%;*/
    padding-top: 1.4%;
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 3%;
    line-height: 1.25em;

}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1px;
}

* { margin: 0; }