nav{
    display: flex;
    flex-direction: row;
    justify-content: space-between; /*space-around*/
    align-items: center;
    min-height: 10vh;
    background-image: linear-gradient(to bottom right, rgb(0, 0, 0), rgb(56, 55, 55));
}

.nav-links{
    min-height: 100%;
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
}

.nav-buttons{
    display: flex;
    text-decoration: none;
    color: gold;
    font-family: Arial, Helvetica, sans-serif;
    align-items: center;
    /*width: 20%;*/
    padding-left: 6%;
    padding-right: 6%;
    height: 10vh;

}

.nav-buttons-logo{
    display: flex;
    text-decoration: none;
    color: gold;
    font-family: Arial, Helvetica, sans-serif;
    align-items: center;
    /*width: 20%;*/
    padding-left: 3%;
    padding-right: 3%;
    height: 10vh;

}

.nav-buttons:hover {
    color: black;
    background-image: linear-gradient(to bottom right, gold, rgba(255, 217, 0, 0.652));

}

.nav-buttons-logo:hover {
    color: black;
    background-image: linear-gradient(to bottom right, gold, rgba(255, 217, 0, 0.652));
}